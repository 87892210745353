import React, { useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { Divider, Fab, useMediaQuery } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import LocalAtmOutlined from '@material-ui/icons/LocalAtmOutlined';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Text from '../common/text.component';
import Button from '../common/button.component';
import { formaterNumber } from '../../utils/currency.utils';
import UsedCar from './UsedCar.component';
import { navigate } from 'gatsby';
import { useAppContext } from '../../context/AppContext';
import {
  DELETE_CREDIT_LINE,
  DELETE_USED_CAR,
  SET_EDIT_USED_CAR,
  SET_FINAL_PRICE
} from '../../context/budget/constants';
import BudgetClientModal from './BudgetClientModal.component';
import {
  goToFinancingLineDetails,
  goToFinancingLines,
  goToIngresar,
  goToRegistroParticular
} from '../../api/navigation';
import setCreditLine from '../../context/budget/actions/setCreditLine';
import FinancingLine from './FinancingLine.component';
import { BUDGET, EMAIL_TYPE } from '../../utils/constants';
import { useMutation } from '@apollo/react-hooks';
import { SEND_BUDGET } from '../../mutations/sendBudget';
import { capitalizeEachWord, getFirstWord } from '../../utils/typography.utils';
import InfoBudgetDialog from './InfoBudgetDialog.component';
import setUserRoute from '../../context/user/actions/setUserRoute';
import { USER_ROLES, isResellerUser } from '../../api/authorization';

const useStyles = makeStyles(theme => ({
  infoContainer: {
    display:'flex',
    flexDirection: 'column',
    width: '100%',
    gap:'1rem',
    [theme.breakpoints.down('sm')]: {
      gap:'0.75rem',
    },
  },
  title:{
    fontSize: theme.typography.size.xLarge,
    color:theme.palette.grayScale.g500,
    lineHeight: '2.8125rem',
    fontStyle: 'normal',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.size.Larger,
      lineHeight:'39px',
    },
  },
  infoBudgetTitle:{
    [theme.breakpoints.down('sm')]: {
      marginTop:'1rem',
    },
  },
  sectionPrice:{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '1rem 1.5rem',
    gap: '0.5rem',
    background: '#F7F7F7',
    borderRadius: '0.5rem',
    marginBottom:'0.5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize:theme.typography.size.medium,
      lineHeight: '27px',
    },
  },
  price:{
    display:'flex',
    justifyContent:'space-between',
    width:'100%',
    fontSize: theme.typography.size.medium,
    lineHeight: '1.6875rem',
    color:theme.palette.grayScale.g500,
  },
  patentingExpensesName:{
    fontWeight:500,
    color:'#5C5C5C',
  },
  patentingExpensesCost:{
    fontWeight:500,
    color:'#5C5C5C',
    minWidth:'5.366rem',
  },
  sectionUseCar_finances:{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px',
    gap: '16px',
    border: '1px dashed  #D1D1D1',
    borderRadius: '8px',
    color:theme.palette.grayScale.g500,
    marginBottom:'0.5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize:theme.typography.size.small,
      lineHeight: '1.5rem',
      padding: '1.375rem',
    },
  },
  addIcon:{
    backgroundColor:'#7D10FF',
    boxShadow:'none',
  },
  balance:{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px',
    gap: '16px',
    background: theme.palette.grayScale.g200,
    borderRadius: '8px',
    fontSize: theme.typography.size.large,
    lineHeight: '1.875rem',
    marginBottom:'1.5rem',
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
    },
  },
  balancePrice:{
    fontSize: theme.typography.size.larger,
    lineHeight: '39px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.375rem',
      lineHeight: '2.063rem',
    },
  },
  buttonReserve:{
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    whiteSpace: 'nowrap',
  },
  sectionButtons:{
    display: 'flex',
    justifyContent: 'end',
    gap: '10px',
    padding: '0',
    margin: '0',
    marginTop:'1rem',
    [theme.breakpoints.down('sm')]: {
      marginTop:'0.5rem',
      marginBottom:'0.5rem',
      justifyContent:'center',
      gap: 27,
    },
  },
  buttonDownloadEmail:{
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '1.5rem',
    color:theme.palette.grayScale.g500,
    '&:hover *':{
      fill:'#7D10FF'
    }
  },
  usedCarsContainer:{
    background: '#F7F7F7',
    borderRadius: '10px',
    padding: '24px 24px 20px',
    gap: '20px',
    marginBottom: '0.5rem'
  },
  sectionInfoUsedCar:{
    marginTop: '1rem'
  },
  sectionInfoUsedCarVersion:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  actionButtons:{
    display: 'flex',
    color: theme.palette.primary.main2,
    marginLeft: '0.5rem',
    marginTop: '1rem',
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize:'1rem',
      lineHeight:'1.5rem'
    },
  },
  btnEdit:{
    marginRight: '1.5rem',
    cursor: 'pointer',
    '&:hover':{
      color: theme.palette.primary.dark,
    }
  },
  btnAddUsedCard:{
    color: theme.palette.primary.main2,
    marginLeft: '0.5rem',
    marginTop: '1.5rem',
    cursor: 'pointer',
    '&:hover':{
      color: theme.palette.primary.dark,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize:'1rem',
      lineHeight:'1.5rem'
    },
  },
  btnLink:{
    color: theme.palette.primary.main2,
    cursor: 'pointer',
    border: 'none',
    margin: '0',
    padding: '0',
    height: 'auto',
    fontSize:'1rem',
    '&:hover':{
      color: theme.palette.primary.dark,
    },
    [theme.breakpoints.down('sm')]: {
      lineHeight:'1.5rem'
    },
  },
  txtBrandModel:{
    color: theme.palette.grayScale.g600,
    fontWeight: 600,
    marginRight: '0.5rem',
  },
  txtVersion:{
    color: theme.palette.grayScale.g600,
    fontWeight: 400,
    textTransform: 'upperCase'
  },
  txtYearKmState:{
    color: theme.palette.grayScale.g400,
    display: 'flex',
    alignItems: 'center',
  },
  divider:{
    width: '4px',
    height: '4px',
    background: theme.palette.grayScale.g400,
    borderRadius: '50%',
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
  },
  priceUsedCar:{
    color: theme.palette.grayScale.g500,
    fontWeight: 500,
  },
  titleUsedCar:{
    marginBottom: '1rem',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '1.25rem',
      fontSize:'1.125rem',
      lineHeight:'1.688rem'
    },
  },
  btnDelete:{
    cursor: 'pointer',
    '&:hover':{
      color: theme.palette.primary.dark,
    }
  },
  dividerUsedCar:{
    marginBottom: '1rem'
  },
  buttonWidthDialogAfter:{
    width:'14.5625rem'
  },
  buttonWidthDialogBefore:{
    width:'17.5rem'
  },
  messageDialogInfo:{
    marginBottom:'1.68rem'
  },
  reserveButtons:{
    display:'flex',
    gap:'1rem',
    [theme.breakpoints.down('md')]: {
      flexDirection:'column-reverse',
      gap: '0.75rem',
    },
  }
}));

const NEW_CAR_COSTS_NAME = 'Gastos de patentamiento';
const USER_CAR_COSTS_NAME = 'Gastos de transferencia';
const MESSAGE_BEFORE_LOGED_IN = <Text>Para reservar este auto necesitás ingresar a tu cuenta o crear una.</Text>;
const LABEL_BTN_BEFORE_LOGED_IN = 'Ingresar a mi cuenta';
const LABEL_BTN_BEFORE_ADD_ACCOUNT = 'Crear una cuenta';
const TITLE_DIALOG_BEFORE_LOGED_IN = '¡Qué buena elección!';

const LABEL_BTN_AFTER_LOGED_IN = 'Cerrar';
const getTitleForResellerDialog = (name) => `¡Hola, ${name}!`;
const BudgetInfo = ({
  price,
  deliveryCosts,
  is0km,
  creditLines,
  usedCars,
  setModalClientOpen,
  modalClientOpen,
  isLoggedIn,
  location
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const creditLineAmount = creditLines?.length;
  const costsName = is0km ? NEW_CAR_COSTS_NAME : USER_CAR_COSTS_NAME;
  const MESSAGE_AFTER_LOGED_IN =
    <Text>
      <div className={classes.messageDialogInfo}><div>Sobre el presupuesto ya actualizamos los valores</div>
        <div> de acuerdo a tu beneficio por ser parte de Carmuv.</div>
      </div>
      <div>Muchas gracias.</div>
    </Text>;
  const costsValue = deliveryCosts || 0;
  const costUsedCar = usedCars.reduce((acumulate, actual) => {
    return acumulate + actual.quote.prices[1].amount;
  }, 0);

  const modalText = useRef({});

  const { budget, budgetDispatch, user, userDispatch, logsInForAReservation } = useAppContext();
  const { creditLine, car } = budget;
  const { profile: { role_name: roleName, firstname: name, lastname: lastName, email, phone } = {} } = user;
  const userProfile = {
    name,
    lastName,
    email,
    phone
  };

  const isResellerOrAdmin = isLoggedIn && isResellerUser(roleName);

  const quebrantoPrice = (creditLine.condition.quebrantoPorc / 100);
  const priceFinancingExpenses = Math.trunc(
    (creditLine.financedAmount * quebrantoPrice)
    + creditLine.condition.gastosFijos)
    || 0;
  const financedAmount = creditLine.financedAmount || 0;

  const totalPrice = price + costsValue - costUsedCar + priceFinancingExpenses - financedAmount;
  const [showAfterInfoBudgetDialog, setShowAfterInfoBudgetDialog] = useState(false);
  const [showBeforeInfoBudgetDialog, setShowBeforeInfoBudgetDialog] = useState(false);

  const addUsedCar = () => {
    navigate('/presupuesto/entrega-auto-usado');
  };

  const onDeleteCreditLineClick = () => {
    budgetDispatch({ type: DELETE_CREDIT_LINE });
  };

  const goToCreditLines = (routeMode) => () => {
    if(creditLineAmount === 1){
      // Se obtiene la linea seleccionada
      let selectedLine = creditLines[0];
      const selectedCondition = selectedLine.lines[0];

      setCreditLine(selectedLine, selectedCondition, budgetDispatch);
      return goToFinancingLineDetails(routeMode);
    }

    goToFinancingLines(routeMode);
  };

  const editFinancingLine = () => {
    return goToFinancingLineDetails(BUDGET.CREDIT_LINE_EDIT_MODE);
  };

  const editUsedCar = (usedCar) => {
    budgetDispatch({
      type: SET_EDIT_USED_CAR,
      data:
        usedCar
      ,
    });
    navigate('/presupuesto/entrega-auto-usado');
  };

  const openModalMail = () => {
    modalText.current = {
      title : 'Dejanos tus datos',
      button : 'Enviar',
      subtitle: 'Te enviaremos el presupuesto por e-mail y un asesor se comunicará con vos a la brevedad.',
    };
    setModalClientOpen(true);
  };

  const openModalPDF = () => {
    modalText.current = {
      title : 'Completá tus datos para tener tu presupuesto',
      button : 'Ver en PDF',
      isPDF: true
    };
    setModalClientOpen(true);
  };

  const deleteUsedCar = (id) => {
    budgetDispatch({
      type: DELETE_USED_CAR,
      data: {
        id
      },
    });
  };

  const [sendBudget, {
    data: emailResponse,
    loading: emailLoading,
  }] = useMutation(SEND_BUDGET);

  const sendEmailAndBudget = async (clientData, budget) => {
    const formattedBrand = capitalizeEachWord(car.brand.name.toLowerCase());
    const formattedModel = capitalizeEachWord(car.model.name.toLowerCase());
    const formattedVersion = car.version.name;

    sendBudget({
      variables: {
        clientData,
        templateData: {
          carName: `${formattedBrand} ${formattedModel} ${formattedVersion}`,
          price: totalPrice
        },
        budget,
        options: {
          hasTakingCar: Boolean(usedCars.length),
          hasFinancing: Boolean(creditLine.id),
        },
        emailType:EMAIL_TYPE.DETAIL
      },
    });

  };

  const shouldOpenAfterInfoBudgetDialog = useCallback(() => {
    if(!logsInForAReservation.current || !isLoggedIn) return;

    if(roleName === USER_ROLES.RESALE) setShowAfterInfoBudgetDialog(true);

    logsInForAReservation.current = false;
  }, [logsInForAReservation, isLoggedIn, roleName]);

  React.useEffect(() => {
    if (emailResponse?.sendBudget?.url) {
      const link = document.createElement('a')
      link.href = emailResponse?.sendBudget?.url
      link.download = 'file.pdf'
      link.target = '_blank'
      link.dispatchEvent(new MouseEvent('click'))
    }

  }, [emailResponse]);

  React.useEffect(() => {
    shouldOpenAfterInfoBudgetDialog();

  }, [shouldOpenAfterInfoBudgetDialog]);

  const handleOnClickPrimaryBeforeLogedIn = () => {
    const { pathname, search } = location;

    setUserRoute(userDispatch, {
      prevRoute: pathname + search,
    });
    setShowBeforeInfoBudgetDialog(false);
    logsInForAReservation.current = true;
    goToIngresar({ preventExitPrompt:true });
  };

  const handleOnClickSecundaryBeforeLogedIn = () => {
    const { pathname, search } = location;

    setUserRoute(userDispatch, {
      prevRoute: pathname + search,
    });

    setShowBeforeInfoBudgetDialog(false);
    logsInForAReservation.current = true;
    goToRegistroParticular({ preventExitPrompt:true });
  };

  const handleOnClickAfterLogedIn = () => {
    setShowAfterInfoBudgetDialog(false);
  };

  const handleOnClickBookCar = () => {
    if(!isLoggedIn) {
      setShowBeforeInfoBudgetDialog(true);
    } else {
      budgetDispatch({
        type: SET_FINAL_PRICE,
        data: { totalPrice, priceFinancingExpenses }
      });
      navigate('/presupuesto/resumen/consena');
    }
  };

  const handleOnClickBookCarWithoutPay = () => {
    if(!isLoggedIn) {
      setShowBeforeInfoBudgetDialog(true);
    } else {
      budgetDispatch({
        type: SET_FINAL_PRICE,
        data: { totalPrice, priceFinancingExpenses }
      });
      navigate('/presupuesto/resumen/sinsena');
    }
  };

  return (
    <>
      <Box id="info-budget-container" className={classes.infoContainer}>
        <Box id="info-budget-title" className={classes.infoBudgetTitle}>
          <Text bold className={classes.title}>Presupuesto</Text>
        </Box>
        <Box id="info-budget-precio" className={classes.sectionPrice}>
          <div className={classes.price}>
            <Text semibolder>Precio</Text>
            <Text semibolder>$ {formaterNumber(price)}</Text>
          </div>
          <div className={classes.price}>
            <Text className={classes.patentingExpensesName}>{costsName}</Text>
            <Text className={classes.patentingExpensesCost}>$ {formaterNumber(costsValue)}</Text>
          </div>
        </Box>
        {usedCars.length <= 0 ? (
          <Box
            id="info-budget-used-car"
            className={classes.sectionUseCar_finances}
          >
            <Text semibolder>¿Querés entregar un auto usado?</Text>
            <Fab
              color="primary"
              size="medium"
              onClick={addUsedCar}
              className={classes.addIcon}
              aria-label="add"
            >
              <AddIcon />
            </Fab>
          </Box>
        ) : (
          <Box id="used-cars" className={classes.usedCarsContainer}>
            <div className={classes.titleUsedCar}>
              <Text bold>Usado como parte de pago</Text>
            </div>
            {usedCars.map((usedCar, index) => {
              return (
                <>
                  <UsedCar key={index} usedCard={usedCar} />
                  <div className={classes.actionButtons}>
                    <div onClick={() => editUsedCar(usedCar)}>
                      <Text bold className={classes.btnEdit}>
                        Editar
                      </Text>
                    </div>
                    <div onClick={() => deleteUsedCar(usedCar.id)}>
                      <Text className={classes.btnDelete} bold>
                        Eliminar
                      </Text>
                    </div>
                  </div>
                  {index === 0 && usedCars.length === 2 ? (
                    <Divider className={classes.dividerUsedCar} />
                  ) : null}
                </>
              );
            })}
            {usedCars.length < 2 && (
              <>
                <Divider />
                <div className={classes.btnAddUsedCard} onClick={addUsedCar}>
                  <Text bold>Agregar otro usado</Text>
                </div>
              </>
            )}
          </Box>
        )}
        { creditLine.financedAmount !== 0 && (
          <Box className={classes.usedCarsContainer}>
            <div className={classes.titleUsedCar}>
              <Text bold>Monto financiado</Text>
            </div>
            <>
              <FinancingLine priceFinancingExpenses={priceFinancingExpenses} financingLine={creditLine} />
              <div className={classes.actionButtons}>
                <div onClick={editFinancingLine}>
                  <Text bold className={classes.btnEdit}>
                        Editar
                  </Text>
                </div>
                <div
                  className={classes.btnDelete}
                  onClick={onDeleteCreditLineClick}
                >
                  <Text bold>Eliminar</Text>
                </div>
              </div>
            </>
          </Box>
        )}
        {creditLine.financedAmount === 0 && creditLineAmount ? (
          <Box
            id="info-budget-finances"
            className={classes.sectionUseCar_finances}
          >
            <Text semibolder>¿Querés financiar?</Text>
            <Fab
              color="primary"
              size="medium"
              onClick={goToCreditLines(BUDGET.CREDIT_LINE_CREATE_MODE)}
              className={classes.addIcon}
              aria-label="add"
            >
              <AddIcon />
            </Fab>
          </Box>
        ) : null}
        <Box id="info-budget-used-car" className={classes.balance}>
          <Text bold >Saldo a cancelar</Text>
          <Text bold className={classes.balancePrice} >$ {formaterNumber(totalPrice)}</Text>
        </Box>
        <Box id="info-budget-used-car" className={classes.reserveButtons}>
          {isResellerOrAdmin &&
            <Button
              className={classes.buttonReserve}
              type="submit"
              data-test-id="reserve-button"
              fullWidth
              outline
              onClick={handleOnClickBookCarWithoutPay}
            >
              Solicitar reserva sin señar
            </Button>
          }
          <Button
            primary
            className={classes.buttonReserve}
            type="submit"
            data-test-id="search-button"
            fullWidth
            onClick={openModalMail}
          >
            Consultar
          </Button>
        </Box>
        <Box id="info-budget-finances" className={classes.sectionButtons}>
          <Button
            outline
            className={classes.btnLink}
            type="submit"
            data-test-id="search-button"
            onClick={openModalPDF}
            startIcon={<InsertDriveFileOutlinedIcon />}
          >
            Ver en PDF
          </Button>
          <Button
            outline
            className={classes.btnLink}
            type="submit"
            data-test-id="search-button"
            onClick={handleOnClickBookCar}
            startIcon={<LocalAtmOutlined />}
          >
            Reservar
          </Button>
        </Box>
      </Box>
      <BudgetClientModal
        open={modalClientOpen}
        setModalClientOpen={setModalClientOpen}
        modalText={modalText}
        isMobile={isMobile}
        loadingRequest={Boolean(emailLoading)}
        requestSuccess={Boolean(emailResponse?.sendBudget?.success)}
        onSubmit={sendEmailAndBudget}
        budget={budget}
        userProfile={userProfile}
        roleName={roleName}
        isRequiredPhone={true}
      />
      <InfoBudgetDialog
        buttonWidth={classes.buttonWidthDialogBefore}
        show={showBeforeInfoBudgetDialog}
        onShow={setShowBeforeInfoBudgetDialog}
        title={TITLE_DIALOG_BEFORE_LOGED_IN}
        message={MESSAGE_BEFORE_LOGED_IN}
        isBtnPrimary
        isBtnSecondary
        lblBtnPrimary={LABEL_BTN_BEFORE_LOGED_IN}
        lblBtnSecondary={LABEL_BTN_BEFORE_ADD_ACCOUNT}
        handleOnClickBtnPrimary={handleOnClickPrimaryBeforeLogedIn}
        handleOnClickBtnSecondary={handleOnClickSecundaryBeforeLogedIn}
        showClose={true}
      />
      <InfoBudgetDialog
        buttonWidth={classes.buttonWidthDialogAfter}
        show={showAfterInfoBudgetDialog}
        onShow={setShowAfterInfoBudgetDialog}
        title={getTitleForResellerDialog(capitalizeEachWord(getFirstWord(name || '')))}
        message={MESSAGE_AFTER_LOGED_IN}
        isBtnPrimary
        lblBtnPrimary={LABEL_BTN_AFTER_LOGED_IN}
        handleOnClickBtnPrimary={handleOnClickAfterLogedIn}
        showClose={true}
      />
    </>
  );
};

BudgetInfo.propTypes = {
  car: PropTypes.object,
};


export default BudgetInfo;
